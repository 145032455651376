<template>
  <div
    class="slotted-dropdown"
    :style="customStyles"
    v-on-click-outside="clickOutside" 
  >
    <div
      :class="['slotted-dropdown__trigger flex items-center gap-x-2', triggerClass]"
      :style="triggerStyles"
      ref="dropdown"
    >
      <div class="flex justify-between items-center" @click.stop="handleOpen">
        <slot name="selected" />
        <i class="w-4 ml-1 mt-0.5"
            :class="['fa', isDropdownActive ? 'fa-angle-up' : 'fa-angle-down']"
        ></i>
      </div>
    </div>
    <div
      v-if="isDropdownActive"
      class="slotted-dropdown__list"
      :style="listStyles"
      ref="list"
    >
        <ul class="bg-white list-none p-0 flex flex-col items-start rounded-b-xl shadow-lg overflow-hidden text-red">
            <li class="w-full flex gap-3 items-center px-3 py-1 slotted-dropdown__list--option" 
                v-for="option in filteredOptions"
                @click.stop="handleSelect($event, option)" 
                :key="option[keyExpr]"
                :title="option[valueAttr]" 
            >
                <slot name="option" v-bind:option="option">
                    {{ option?.[labelAttr] }}
                </slot>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>

export default {
    components: {
        
    },

    props: {
        value: {
            type: Object,
            default: ()=>({}),
        },
        options: {
            type: Array,
            required: true,
        },
        keyExpr: {
            type: String,
            default: 'id',
        },
        labelAttr: {
            type: String,
            default: 'status_name',
        },
        valueAttr: {
            type: String,
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideSelected: {
            type: Boolean,
            default: true,
        },
        placement: {
            type: String,
            default: 'left',
        },
        minOptionWidth: {
            type: Number,
            default: 120,
        },
        triggerClass: {
            type: String,
            default: '',
        },
        triggerStyles: {
            type: [String, Object],
            default: '',
        },
        listStyles: {
            type: [String, Object],
            default: '',
        },
        onSelect: {
            type: Function,
            default: async () => {}
        },
        customStyles: {
            type: [String, Object],
            default: "",
        }
    },

    data() {
        return {
            isDropdownActive: false
        }
    },

    watch: {
        isDropdownActive(val) {
            if (val) this.placeDropdown()
        }
    },
    computed: {
        filteredOptions() {
            return this.hideSelected ? this.options.filter(x=> x?.[this.valueAttr] !== this.value?.[this.valueAttr]) : this.options
        }
    },

    methods: {
        clickOutside() {
            this.isDropdownActive = false;
        },
        handleOpen() {
            if (this.disabled) return;
            this.isDropdownActive = !this.isDropdownActive
        },
        async handleSelect($event, option) {
            this.handleOpen();
            
            if (this.value?.[this.valueAttr] === option?.[this.valueAttr]) return;

            const payload = { $event, option };
            
            await this.onSelect(payload);

            this.$emit('input', payload);
        },
        
        openTop() {
            const listRectangle = this.$refs.list.getBoundingClientRect();
            return  listRectangle.bottom >= window.innerHeight;
        },
        placeDropdown() {
            if (this.isDropdownActive) {
                this.$nextTick(() => {
                    let { top, bottom, width, left, right } = this.$el.getBoundingClientRect();
                    const container = this.$refs.dropdown;
                    const listEl = this.$refs.list;

                    if (container && listEl) {
                        container.style.width = "100%";

                        listEl.style.width = `${width}px`;
                        listEl.style.minWidth = `${this.minOptionWidth || width}px`;

                        if (this.placement == 'right') {
                            left = right - this.minOptionWidth;
                        }

                        listEl.style.left = `${left}px`;
                        listEl.style.top = 'auto'

                        if (this.openTop()) {
                            const listHeight = listEl.getBoundingClientRect().height;
                            listEl.style.top = `${top - listHeight}px`;
                        } else {
                            listEl.style.top = `${bottom}px`;
                        }
                    }
                });
            }
        }
    },
    created() {
        document.addEventListener("scroll", this.placeDropdown, true);
        window.addEventListener("resize", this.placeDropdown);
    },
    destroyed() {
        document.removeEventListener("scroll", this.placeDropdown, true);
        window.removeEventListener("resize", this.placeDropdown);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';
.slotted-dropdown__list {
    position: fixed;
    z-index: 1000;
    color: grey;
}

.slotted-dropdown__list--option:hover {
    background-color: #cce3ff;
    cursor: pointer;
}
</style>
