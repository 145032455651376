var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity flex items-center justify-center gap-6 h-10 pl-7 pr-4 py-2",class:{
        'bg-transparent border-box py-2': !_vm.item.selected && !_vm.item.warning && !_vm.item.error,
        'bg-error': _vm.item.error && !_vm.item.selected,
        'opacity-50 pointer-events-none cursor-not-allowed': _vm.item.disabled,
        'cursor-pointer': _vm.item.selectable
    },style:({'border': _vm.item.selected ? `2px solid ${_vm.itemStatus?.status_colour}` : '' }),on:{"click":_vm.onSelect}},[_c('div',{staticClass:"entity__content flex items-center justify-center gap-2"},[(_vm.item.type in _vm.entityTypesConfig)?_c('div',{staticClass:"entity__content-underlay flex items-center justify-center",class:{ 'invisible': _vm.item.selected },style:({ 'border': `2px solid ${_vm.itemStatus?.status_colour}` })}):_vm._e(),(_vm.item.type in _vm.entityTypesConfig)?_c('div',{staticClass:"entity__icon-wrapper flex items-center justify-center relative rounded-full"},[_c('span',{staticClass:"flex items-center justify-center w-5 h-5 rounded-full",class:!_vm.itemStatus?.status_colour && {
                    'bg-primary': !_vm.item.selected,
                    'bg-white': _vm.item.selected
                },style:({ 'background-color': _vm.itemStatus?.status_colour || '' })},[_c(_vm.entityTypesConfig?.[_vm.item.type]?.icon,{tag:"component",staticClass:"entity__icon",class:{ 'selected': !_vm.itemStatus?.status_colour && _vm.item.selected }})],1)]):_vm._e(),_c('div',{staticClass:"entity__name w-48"},[_c('p',{staticClass:"text-sm font-bold truncate",attrs:{"title":_vm.itemName}},[_vm._v(" "+_vm._s(_vm.itemName)+" ")])])]),(_vm.showControls)?_c('div',{staticClass:"entity__controls ml-auto flex gap-1 items-center"},[(_vm.editable)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                content: 'Edit',
            }),expression:"{\n                content: 'Edit',\n            }"}],staticClass:"flex items-center justify-center text-primary",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit')}}},[_c('EditIcon',{staticClass:"entity__btn-icon",class:{ 'selected': _vm.item.selected }})],1):_vm._e(),(!_vm.readonly && _vm.deletable)?_c('button',{staticClass:"flex items-center justify-center text-primary",attrs:{"title":"Delete"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}},[_c('TrashIcon',{staticClass:"entity__trash",class:{ 'selected': _vm.item.selected }})],1):_vm._e(),(_vm.item.statusList?.length)?_c('DropdownMenu',{attrs:{"value":_vm.itemStatus,"value-attr":"status_id","options":_vm.item.statusList,"placement":"right","min-option-width":178,"list-styles":`margin-top:15px`,"onSelect":(e) => _vm.handleEntityChangeStatus({ entity: _vm.item, status: e.option })},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('span',{staticClass:"rounded-full w-5 border border-solid border-gray-300 flex-shrink-0 h-5",style:({'background-color': option?.status_colour })}),_c('div',{staticClass:"text-base truncate max-w-full text-sm",attrs:{"title":option?.status_name}},[_vm._v(" "+_vm._s(option?.status_name)+" ")])]}}],null,false,3624130821)}):_vm._e()],1):(_vm.item.checked)?_c('div',{staticClass:"entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"},[_c('font-awesome-icon',{staticClass:"text-primary-content text-xs",attrs:{"icon":"check"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }