<template>
    <div
        class="plus-icon__wrapper flex items-center justify-center rounded-full cursor-pointer w-8 border-2 border-primary-focus h-8"
        :class="{
            'bg-transparent': !item.selected && !item.warning && !item.error,
            'bg-error': item.error && !item.selected,
            'opacity-50 pointer-events-none cursor-not-allowed': item.disabled,
            'cursor-pointer': item.selectable
        }"
        type="primary"
        :disabled="disabled"
        v-on="$listeners"
    >
        <font-awesome-icon
            class="plus-icon__icon text-xl"
            :class="{
                'text-primary': !item.selected && !item.warning && !item.error
            }"
            :icon="['fa', 'info']"
        />
    </div>
</template>

<script>
// import Button from "@shared/components/button/index.vue";

export default {
    components: {
        // Button,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>