import { permissionErrorMessages } from "@shared/utils/permissions/constants.js";
import { getPermissionCode } from "@shared/utils/functions";

export class PermissionError extends Error {
  constructor(permission, message) {

    const resultMessage = message ?? permissionErrorMessages[permission] ?? "Permission error";

    super(resultMessage);

    this.name = "PermissionError";
    this.permission = permission;
    this.code = getPermissionCode(permission);
  }
}