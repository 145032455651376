<template>
  <ModalContent
    ref="popup"
    name="state-transition-form"
    width="80%"
    :max-width="1200"
    :show-footer="false"
    :click-to-close="false"
    v-bind="$attrs"
    @cancel="cancel"
  >
    <Loader v-if="isLoading" />

    <FormBuilder
      v-else-if="formData"
      class="form-builder"
      :data="formData"
      :form-states="formStates"
      :case_id="caseData.case_id"
      :app="currentApp"
      :save-section-method="saveSectionMethod"
      :show-preview-button="false"
      @submit="submitForm"
    />

    <Empty
      v-else
      text="There is no data to display"
      classList="text-lg"
      :showBtn="false"
    />
  </ModalContent>
</template>

<script>
import axios from "@/axios";

import handleError from "@shared/mixins/handleError.js";

import ModalContent from "@shared/modal-content";
import FormBuilder from "@shared/components/form-builder";
import Loader from "@shared/components/WrappedLoader";
import Empty from "@shared/empty";

import FormBuilderAPIUrlList from "@shared/components/form-builder/utils/api";

import {
  formatDataForFormPreview,
  formatSections,
} from "@shared/case-entities/utils";
import { appList } from "@shared/components/form-builder/utils/index.js";


export default {
  components: {
    ModalContent,
    FormBuilder,
    Loader,
    Empty,
  },

  mixins: [handleError],

  props: {
    formId: {
      type: String,
      required: true,
    },
  },

  inject: ["sharedCaseState", "currentApp"],

  data() {
    return {
      isLoading: false,
      formData: null,
      API: null,
      formStates: {
        loading: false,
        submitted: false,
      },
      customApi: {
        fetchForm: (payload) =>
          `case/${payload.case_id}/entity/${payload.entity_id}/additional-forms/${payload.form_id}`,
        saveSection: (payload) =>
          `case/${payload.case_id}/entity/${payload.entity_id}/additional-forms/${payload.form_id}`,
        submitForm: (payload) =>
        `/case${ this.currentApp == appList.CLIENT ? '/client' : ''}/${payload.case_id}/entity/${payload.entity_id}/form/${payload.form_id}/submit`
      },
      error: null,
    };
  },

  created() {
    const { case_id, entity_id } = this.caseData;
    this.API = new FormBuilderAPIUrlList(
      {
        case_id,
        entity_id,
        form_id: this.formId,
      },
      this.customApi
    );
  },

  async mounted() {
    this.$refs.popup.showModal();
    try {
      this.isLoading = true;

      const url = this.API.fetchForm;
      const { data } = await axios.get(url);
      this.formData = formatDataForFormPreview(data);
    } catch (error) {
      this.onError(error);
    }
    this.isLoading = false;
  },

  computed: {
    caseData() {
      return this.sharedCaseState();
    },
    isCaseSubmitted() {
      return this.caseData.is_case_submitted;
    },
    currentWorkflowObjectId() {
      return this.caseData.current_workflow_object_id;
    },
  },

  methods: {
    onError(error) {
      this.handleError(error);
      this.error = error;
    },

    async saveSectionMethod(payload) {
      this.formStates.loading = true;
      try {
        const url = this.API.saveSection;
        await axios.post(url, formatSections(payload));
      } catch (error) {
        this.onError(error);
      }
      this.formStates.loading = false;
    },

    async submitTransitionForm() {
      const url = "case/state-transition-form-submitted";
      const payload = {
        case_additional_form_id: this.formId,
      }
      // for the case (after case has been submitted), we send a specific `case_id` key:
      const objectKey = this.isCaseSubmitted ? "case_id" : "case_entity_id";
      payload[objectKey] = this.currentWorkflowObjectId;

      await axios.post(url, payload);
    },

    async submitForm() {
      this.formStates.loading = true;
      try {
        const url = this.API.submitForm;
        await axios.post(url);
        await this.submitTransitionForm();
        this.$emit("submit");
      } catch (error) {
        this.onError(error);
      }
      this.formStates.loading = false;

      this.$refs.popup.hideModal();
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-builder {
  background-color: white !important;
}
</style>