<template>
  <DefaultDashboard
    is-default-dashboard
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
// TODO get rid of dependencies from the current app components & store!

import DefaultDashboard from "@/components/dashboard-components/dashboard-view/DefaultDashboard"

export default {
  components: {
    DefaultDashboard,
  },
}
</script>

<style lang="scss" scoped>

</style>